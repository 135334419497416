import axios from 'axios';
import { authOut } from 'redux/authReducer';

const url = process.env.REACT_APP_API_URL_API;

const instance = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': `${url}`,
    timeout: 120000
  }
});

const interceptor = (store) => {
  instance.interceptors.response.use(response => response, interseptorsFunc);
  function interseptorsFunc(requestError) {
    const status = requestError?.response?.status;
    if (status === 403 || status === 401) {
      store.dispatch(authOut());
      instance.defaults.headers.common.Authorization = null;
    }
    return Promise.reject(requestError);
  }
};

function updateToken(token) {
  token ? instance.defaults.headers.common.Authorization = `Bearer ${token}` : instance.defaults.headers.common.Authorization = null;
}
const apiToken = instance?.headers?.common?.Authorization;
export { instance as api, updateToken, apiToken, interceptor };
