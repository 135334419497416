import { LoadWrap } from 'components';
import { useChartsClientsTraffic } from 'api/requests';
import classes from './ClientTrafficGraph.module.scss';

export default function ClientTrafficGraph({ body }) {
  const { data, error, isFetching } = useChartsClientsTraffic(body);

  function lineWidth(value) {
    const values = data.map(el => el.traffic);
    const maxValue = Math.max.apply(null, values);
    const findPercent = Math.ceil(100 / (maxValue / value));
    return `${findPercent}%`;
  }
  return (
    <div className={classes.clientTrafficWrapper}>
      <div className={classes.header}>Clients Traffic</div>
      <div className={classes.body}>
        <LoadWrap data={!!data?.length} isFetching={isFetching} error={error}>
          <>
            { !!data?.length && data.map(client => (
              <div key={client.name} className={classes.clientWrap}>
                <div className={classes.name}>{client.name}</div>
                <div className={classes.lineWrap}>
                  <div className={classes.line} style={{ width: lineWidth(client.traffic) }} />
                </div>
                <div className={classes.traffic}>{client.traffic || '-'}</div>
              </div>
            ))}
          </>
        </LoadWrap>
      </div>
    </div>
  );
}
