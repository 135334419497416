import { useRef } from 'react';
import { VisitLangGraph, ClientsMenu } from 'components';
import 'antd/dist/reset.css';
import { CSSTransition } from 'react-transition-group';
import classes from './RightBar.module.scss';
import transitionRight from './transitionRight.module.scss';

export default function RightBar({ visible, companyInfoTotal, companyInfo }) {
  const rightRef = useRef(null);

  return (
    <CSSTransition
      in={visible}
      timeout={450}
      classNames={transitionRight}
      unmountOnExit
      nodeRef={rightRef}
    >
      <div className={classes.rightBar} ref={rightRef}>
        <VisitLangGraph companyInfoTotal={companyInfoTotal} />
        <ClientsMenu companyInfo={companyInfo} />
      </div>
    </CSSTransition>
  );
}
