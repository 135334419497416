import { useState } from 'react';
import AddCompany from 'assets/svg/add_comp.svg';
import { CompanyInfo, ModalBodyView, ModalBodyAddVenue, ModalBodyEditCompany, ModalBodyDeleteVenue, ModalBodyDeleteCompany } from 'components';
import { Modal } from 'antd';
// import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import classes from './Administration.module.scss';

export default function Administration() {
  const { companyInfo } = useOutletContext();
  // const companies = useSelector(state => state?.companies?.infoCompanies);
  const companies = companyInfo?.data;

  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [compInfo, setCompInfo] = useState(null);

  const modalBody = {
    add: <ModalBodyEditCompany company={compInfo} onClose={closeModal} add />,
    edit: <ModalBodyEditCompany company={compInfo} onClose={closeModal} />,
    create: <ModalBodyAddVenue company={compInfo} onClose={closeModal} />,
    view: <ModalBodyView company={compInfo} />,
    delete: <ModalBodyDeleteVenue venue={compInfo} onClose={closeModal} />,
    deleteCompany: <ModalBodyDeleteCompany company={compInfo} onClose={closeModal} />
  };

  function closeModal() {
    setOpen(false);
    setModalType(null);
    setCompInfo(null);
    Modal.destroyAll();
  }

  function onChangeCompany({ type, company }) {
    setOpen(true);
    setModalType(type);
    setCompInfo(company);
  }

  return (
    <div className={classes.administrationWrap}>
      <div className={classes.administrationContainer}>
        <div className={classes.headerWrapp}>
          <div className={classes.header}>Companies and Users</div>
          <div className={classes.imageWrapp} onClick={() => onChangeCompany({ type: 'add', company: null })}>
            <img src={AddCompany || ''} alt="add company" className={classes.image} />
          </div>
        </div>
        <div>
          {!!companies?.length && companies.map(company => (
            <CompanyInfo
              key={company?.id}
              company={company}
              onChangeCompany={onChangeCompany}
            />
          ))}
        </div>
      </div>
      <Modal
        destroyOnClose
        closable={false}
        centered
        open={open}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
        className={classes.modal}
        bodyStyle={{ backgroundColor: '#333333' }}
        width="auto"
      >
        {modalBody[modalType]}
      </Modal>
    </div>
  );
}
