import { memo, useState } from 'react';
import { string, func, bool, number, oneOfType, shape } from 'prop-types';
import cn from 'classnames';
import { toPascalCase } from 'utils/common';
import * as svg from 'icons';
import InnerHTML from 'dangerously-set-html-content';
import { Tooltip } from 'components';
import classes from './icon.module.scss';
import './icon.css';

export const Icon = memo((props) => {
  const [state, setState] = useState({ btnClass: '' });
  const {
    type,
    id,
    onClick,
    color,
    opacity,
    role,
    btnType,
    tooltipText,
    text,
    textClass,
    className,
    href,
    width,
    height,
    style,
    tooltipProps,
    ...other
  } = props;

  const IconComp = svg[toPascalCase(type)];

  if (role === 'button') {
    return (
      <Tooltip
        text={typeof tooltipText === 'string' ? <InnerHTML html={tooltipText} /> : tooltipText}
        className={cn(className, classes.icon_btn)}
      >
        <div {...(id ? { id } : null)} {...(onClick ? { onClick } : null)} className={cn(btnType && btnType)}>
          {IconComp && (
          <IconComp
            className={cn(classes.icon, className)}
            opacity={opacity}
            {...(color ? { color } : null)}
            {...(width ? { width } : null)}
            {...(height ? { height } : null)}
          />
          )}
          {text && <div style={{ ...(color ? { color } : null) }} className={textClass}>{text}</div>}
        </div>
      </Tooltip>
    );
  }
  if (role === 'icon') {
    return (
      <IconComp
        className={className}
        onClick={onClick}
        opacity={opacity}
        color={color}
        type={type}
        width={width}
        height={height}
        style={style}
        {...other}
      />
    );
  }

  return (
    <a
      {...(id ? { id } : null)}
      {...(href ? { href } : null)}
      {...(onClick ? { onClick } : null)}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
      className={className}
      onMouseEnter={() => setState({ btnClass: 'hover-btn' })}
      onMouseLeave={() => setState({ btnClass: '' })}
    >
      {IconComp && (
      <IconComp
        className={state.btnClass}
        {...(onClick ? { onClick } : null)}
        {...(opacity ? { opacity } : null)}
        {...(color ? { color } : null)}
        type={type}
        {...other}
      />
      )}
      {text && <div style={{ ...(color ? { color } : null) }} className={textClass}>{text}</div>}
    </a>
  );
});

Icon.defaultProps = {
  btnType: '',
  id: '',
  onClick: false,
  color: '',
  opacity: 1,
  tooltipText: '',
  className: '',
  href: '',
  width: 0,
  height: 0,
  style: () => null,
  fill: '',
  tooltipProps: () => null,
  role: '',
  text: '',
  textClass: ''

};

Icon.propTypes = {
  id: string,
  onClick: oneOfType([func, bool]),
  color: string,
  opacity: number,
  tooltipText: oneOfType([number, string]),
  className: string,
  href: string,
  width: number,
  height: number,
  style: oneOfType([func, shape({})]),
  fill: string,
  tooltipProps: oneOfType([func, shape({})]),
  type: string.isRequired,
  role: string,
  btnType: string,
  text: oneOfType([string, number]),
  textClass: string
};
