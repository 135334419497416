import { useCallback } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { useCompanyInfoTotal } from 'api/requests';
import { LoadWrap } from 'components';
import { randomHexColor100 } from 'utils/common';
import classes from './VisitLangGraph.module.scss';

const VisitLangGraph = ({ companyInfoTotal }) => {
  const { data, error, isFetching } = companyInfoTotal;
  const fakeLang = [{ id: 1, label: 'EN', value: 0.1, color: '#A3FC95' }, { id: 2, label: 'FR', value: 0.1, color: '#95A4FC' }];

  function getColor(value) {
    if (value === 'en') return '#A3FC95';
    if (value === 'fr') return '#95A4FC';
    return randomHexColor100();
  }
  const sortLangByValue = data?.language?.length ? [...data.language].sort((a, b) => (a?.count < b?.count ? 1 : -1)) : [];

  const language = sortLangByValue?.length
    ? sortLangByValue?.map(el => (
      { id: `${el.visit_language}`.toUpperCase(),
        label: `${el.visit_language}`.toUpperCase(),
        value: el.count,
        color: getColor(el.visit_language)
      })) : fakeLang;
  const colors = language?.map(item => item?.color);

  const tooltip = useCallback(
    ({ datum: { color, label, value } }) => (
      <div className={classes.tooltip}>
        <div className={classes.tooltip_point} style={{ backgroundColor: color }} />
        <div className={classes.tooltip_value}>{`${label}: ${value}`}</div>
      </div>
    ),
    []
  );

  return (
    <div className={classes.visitsWrapper}>
      <LoadWrap data={data} isFetching={isFetching} error={error}>
        <ResponsivePie
          data={language}
          margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
          startAngle={90}
          endAngle={-360}
          innerRadius={0.7}
          padAngle={0}
          colors={colors}
          cornerRadius={1}
          fit={false}
          activeOuterRadiusOffset={2}
          enableArcLinkLabels={false}
          enableArcLabels={false}
          tooltip={tooltip}
          width={200}
          height={200}
        />
        <div className={classes.label}>{!!language?.length && language.map(el => <div key={el.id} style={{ color: el.color }}>{`${el.label}: ${+el.value >= 1 ? el.value : '-'}`}</div>)}</div>
      </LoadWrap>
    </div>

  );
};
export default VisitLangGraph;
