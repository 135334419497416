/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import { useQuery } from 'react-query';
import { api } from 'api/apiInstance';

// AUTH
export const authLoginAdmin = async (body) => {
  if (!body) return;
  const { data } = await api.post('auth/login/admin', body);
  return data?.data;
};
export const authLoginCompany = async (body) => {
  if (!body) return;
  const { data } = await api.post('auth/login/company', body);
  return data?.data;
};

export const authLoginUser = async (body) => {
  if (!body) return;
  const { data } = await api.post('auth/login/user', body);
  return data?.data;
};
export const authLogout = async () => {
  const { data } = await api.post('auth/logout');
  return data?.data;
};
export const authRegister = async (body) => {
  if (!body) return;
  const { data } = await api.post('auth/register', body);
  return data?.data;
};

// CHARTS
export const chartsClientsTraffic = async (body) => {
  const { data } = await api.post('charts/clients-traffic', body || null);
  return data?.data;
};
export const chartsTotal = async (body) => {
  const { data } = await api.post('charts/total', body || null);
  return data?.data;
};
export const chartsTotalCompanyId = async (companyId, body) => {
  const { data } = await api.post(`charts/total-company/${companyId}`, body || null);
  return data?.data;
};
export const chartsTotalVenueId = async (venueId, body) => {
  const { data } = await api.post(`charts/total-venue/${venueId}`, body || null);
  return data?.data;
};
export const chartsVisitsCurrent = async (body) => {
  const { data } = await api.post('charts/visits-current', body || null);
  return data?.data;
};
export const chartsVisitsCurrentCompanyId = async (companyId, body) => {
  const { data } = await api.post(`charts/visits-current/company/${companyId}`, body || null);
  return data?.data;
};
export const chartsVisitsCurrentVenueId = async (venueId, body) => {
  const { data } = await api.post(`charts/visits-current/venue/${venueId}`, body || null);
  return data?.data;
};
export const chartsVisitsPrevious = async (body) => {
  const { data } = await api.post('charts/visits-previous', body || null);
  return data?.data;
};
export const chartsVisitsPreviousCompanyId = async (companyId, body) => {
  const { data } = await api.post(`charts/visits-previous/company/${companyId}`, body || null);
  return data?.data;
};
export const chartsVisitsPreviousVenueId = async (venueId, body) => {
  const { data } = await api.post(`charts/visits-previous/venue/${venueId}`, body || null);
  return data?.data;
};

// COMPANIES INFO
export const companyInfo = async (body) => {
  const { data } = await api.post('info/companies', body || null);
  return data?.data;
};

export const companyInfoTotal = async (body) => {
  const { data } = await api.post('info/total', body || null);
  return data?.data;
};
export const companyInfoTotalId = async (companyId, body) => {
  const { data } = await api.post(`info/total-company/${companyId}`, body || null);
  return data?.data;
};
export const venueInfoTotalId = async (venueId, body) => {
  const { data } = await api.post(`info/total-venue/${venueId}`, body || null);
  return data?.data;
};

// ADMIN INFO
export const adminInfoGet = async () => {
  const { data } = await api.get('admin');
  return data?.data;
};
export const adminInfoPost = async (body) => {
  const { data } = await api.post('admin', body || null);
  return data?.data;
};
export const uploadAvatarAdmin = async (id, body) => {
  const { data } = await api.post(`admin/upload-avatar/${id}`, body || null);
  return data?.data;
};
export const adminInfoIdGet = async (id) => {
  const { data } = await api.get(`admin/${id}`);
  return data?.data;
};
export const deleteAdminId = async (id) => {
  const { data } = await api.delete(`admin/${id}`);
  return data?.data;
};

// ME INFO
export const meInfo = async () => {
  const { data } = await api.get('me');
  return data?.data;
};

// VISIT VENUE
export const sendVisitVenueData = async (body) => {
  const { data } = await api.post('send-visit-venue-data', body || null);
  return data?.data;
};
export const sendVisitVenueDataPublic = async (body) => {
  const { data } = await api.post('send-visit-venue-data-public', body || null);
  return data?.data;
};

// USER INFO
export const userInfoGet = async () => {
  const { data } = await api.get('user');
  return data?.data;
};

export const userInfo = async (body) => {
  const { data } = await api.post('user', body || null);
  return data?.data;
};
export const userUploadAvatar = async (id, body) => {
  const { data } = await api.post(`user/upload-avatar/${id}`, body || null);
  return data?.data;
};
export const userInfoIdGet = async (id) => {
  const { data } = await api.get(`user/${id}`);
  return data?.data;
};
export const userInfoId = async (id, body) => {
  const { data } = await api.post(`user/${id}`, body || null);
  return data?.data;
};
export const deleteUserId = async (id) => {
  const { data } = await api.delete(`user/${id}`);
  return data?.data;
};

// VENUE
export const createVenue = async (body) => {
  const { data } = await api.post('venue', body);
  return data?.data;
};

export const deleteVenue = async (id) => {
  const { data } = await api.delete(`venue/${id}`);
  return data?.data;
};

export const deleteCompany = async (id) => {
  const { data } = await api.delete(`company/${id}`);
  return data?.data;
};
export const addEditCompany = async (body, id) => {
  const queryString = id ? `company/${id}` : 'company';
  const { data } = await api.post(queryString, body);
  return data?.data;
};

export function getCharts(body, id) {
  if (id?.venue_id) return chartsTotalVenueId(id.venue_id, body);
  if (id?.company_id) return chartsTotalCompanyId(id.company_id, body);
  return chartsTotal(body);
}
export function getChartsVisitsCurrent(body, id) {
  if (id?.venue_id) return chartsVisitsCurrentVenueId(id.venue_id, body);
  if (id?.company_id) return chartsVisitsCurrentCompanyId(id.company_id, body);
  return chartsVisitsCurrent(body);
}
export function getChartsVisitsPrevious(body, id) {
  if (id?.venue_id) return chartsVisitsPreviousVenueId(id.venue_id, body);
  if (id?.company_id) return chartsVisitsPreviousCompanyId(id.company_id, body);
  return chartsVisitsPrevious(body);
}

export function getCompanyInfoTotal(body, id) {
  if (id?.venue_id) return venueInfoTotalId(id.venue_id, body);
  if (id?.company_id) return companyInfoTotalId(id.company_id, body);
  return companyInfoTotal(body);
}

// AUTH
export const useAuthLoginAdmin = body => useQuery({ queryKey: ['loginAdmin', body], queryFn: () => authLoginAdmin(body) });
export const useLogout = () => useQuery({ queryKey: 'logout', queryFn: () => authLogout() });
export const useAuthLoginCompany = body => useQuery({ queryKey: ['loginCopany', body], queryFn: () => authLoginCompany(body) });
export const useAuthLoginUser = body => useQuery({ queryKey: ['loginUser', body], queryFn: () => authLoginUser(body) });
export const useRegister = () => useQuery({ queryKey: 'register', queryFn: () => authRegister() });

// CHARTS
export const useChartsClientsTraffic = body => useQuery({
  queryKey: ['charts/clients-traffic', body],
  queryFn: () => chartsClientsTraffic(body),
  enabled: ['charts/total'].isSuccess
});
export const useChartsTotal = (body, id) => useQuery({
  queryKey: ['charts/total', body, id],
  queryFn: () => getCharts(body, id),
  enabled: ['info/total-company'].isSuccess

});
export const useChartsVisitsCurrent = (body, id) => useQuery({
  queryKey: ['charts/visits-current', body, id],
  queryFn: () => getChartsVisitsCurrent(body, id),
  enabled: ['charts/visits-previous'].isSuccess
});
export const useChartsVisitsPrevious = (body, id) => useQuery({
  queryKey: ['charts/visits-previous', body, id],
  queryFn: () => getChartsVisitsPrevious(body, id)

});

// COMPANIES INFO
export const useCompanyInfo = body => useQuery({ queryKey: ['info/companies', body], queryFn: () => companyInfo(body) });
export const useCompanyInfoTotal = (id, body) => useQuery({ queryKey: ['info/total', id, body],
  queryFn: () => getCompanyInfoTotal(id, body)
});

// ADMIN INFO
export const useAdminInfoGet = () => useQuery(['admin-get'], () => adminInfoGet());
export const useAdminInfoPost = body => useQuery(['admin-post', body], () => adminInfoPost(body));
export const useUploadAvatarAdmin = (id, body) => useQuery(['admin/upload-avatar/id', id, body], () => uploadAvatarAdmin(id, body));
export const useAdminInfoIdGet = id => useQuery(['admin/id/get', id], () => adminInfoIdGet(id));
export const useDeleteAdminId = id => useQuery(['admin/id/delete', id], () => deleteAdminId(id));

// ME INFO
export const useMeInfoGet = () => useQuery(['me'], () => meInfo());

// VISIT VENUE
export const useSendVisitVenueData = body => useQuery(['send-visit-venue-data', body], () => sendVisitVenueData(body));
export const useSendVisitVenueDataPublic = body => useQuery(['send-visit-venue-data-public', body], () => sendVisitVenueDataPublic(body));

// USER INFO
export const useUserInfoGet = () => useQuery(['user-get'], () => userInfoGet());
export const useUserInfo = body => useQuery(['user-post', body], () => userInfo(body));
export const useUserUploadAvatar = (id, body) => useQuery(['user/upload-avatar/id', id, body], () => userUploadAvatar(id, body));
export const useUserInfoIdGet = id => useQuery(['user/id-get', id], () => userInfoIdGet(id));
export const useUserInfoIdCustom = (id, body) => useQuery(['user/id-post', id, body], () => userInfoId(id, body));
export const useDeleteUserId = id => useQuery(['user/id-delete', id], () => deleteUserId(id));
