import { useEffect, useState } from 'react';
import { Button, Form, Input, Card, Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { authIn } from 'redux/authReducer';
import { useAuthLoginAdmin } from 'api/requests';

import {
  useNavigate
} from 'react-router-dom';
import classes from './login.module.scss';

const { Content } = Layout;

export default function Login() {
  const dispatch = useDispatch();
  const [credits, setCredits] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const accessToken = useSelector(state => state?.auth?.accessToken);
  const navigate = useNavigate();
  const { data, isFetching, error } = useAuthLoginAdmin(credits);
  useEffect(() => {
    if (!isFetching && data) {
      dispatch(authIn(data));
    }
    if (!isFetching && error) {
      const err = error?.response?.data?.message;
      setErrorMessage(err);
    }
  }, [data, error]);

  useEffect(() => {
    if (accessToken) {
      navigate('overview');
    }
  }, [accessToken]);

  const onFinish = ({ email, password }) => {
    setCredits({ password, email });
  };

  const onClearError = () => {
    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  return (
    <Content className={classes.loginWrap}>
      <Card
        className={classes.login}
        bodyStyle={{ width: '348px' }}
      >

        <Form
          onFinish={onFinish}
          onFocus={onClearError}
          autoComplete="off"
        >
          <Form.Item>
            <div className={classes.header}>Emagine Dash</div>
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!'
              }
            ]}
          >
            <Input placeholder="Email" className={classes.input} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
          >
            <Input.Password placeholder="Passsword" className={classes.input} style={{ color: '#1c1c1c' }} />
          </Form.Item>
          {!!errorMessage && <div className={classes.inputError}>{errorMessage}</div>}
          <Form.Item>
            <Button className={classes.button} htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Content>
  );
}
