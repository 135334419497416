import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { func } from 'prop-types';

const PrivateRoute = ({ component: RouteComponent }) => {
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  return isAuthenticated ? <RouteComponent /> : <Navigate to="/login" replace />;
};

PrivateRoute.defaultProps = {
  component: () => null
};
PrivateRoute.propTypes = {
  component: func
};
export default PrivateRoute;
