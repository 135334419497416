import { LoadWrap } from 'components';
import cn from 'classnames';
import classes from './BlueBlock.module.scss';

export default function BlueBlock({ header, value, isFetching, error, imageStyle, className }) {
  const valueTransform = value?.length ? value.map((el, i) => (<span key={i}>{` ${el?.visit_language}: ${el?.count} `}</span>)) : 0;
  return (
    <div className={classes.blueBlock}>
      <div className={classes.blueBlock_header}>{header}</div>
      <LoadWrap data={value} isFetching={isFetching} error={error} imageStyle={imageStyle}>
        <div className={cn(classes.blueBlock_body, className)}>
          {Array.isArray(value) ? valueTransform : value}
        </div>
      </LoadWrap>
    </div>
  );
}
