import { useState } from 'react';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import classes from './Calendar.module.scss';
import './Calendar.css';

export default function Calendar({ onCalendarChange, date }) {
  // const { startDate, endDate, selection } = date;
  const [state, setState] = useState([
    {
      startDate: moment(date.startDate).toDate(),
      endDate: moment(date.endDate).toDate(),
      key: 'selection'
    }
  ]);

  function hadleChange({ selection }) {
    setState([selection]);
    if (selection.startDate !== selection.endDate) {
      onCalendarChange(selection);
    }
  }

  return (
    <div className={classes.calendarWrapper}>
      <DateRange
        onChange={hadleChange}
        showSelectionPreview={false}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
        preventSnapRefocus
        calendarFocus="backwards"
        rangeColors={['#F4D6D0']}
      />
    </div>
  );
}
