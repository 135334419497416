import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { toUpperLatter } from 'utils/common';

export default function Breadcrumbs() {
  // const matches = useMatches();

  // const crumbs = !!matches && matches?.length && matches
  //   .filter(match => Boolean(match.handle?.crumb))
  //   .map(match => match.handle.crumb(match.params?.id));

  // const breadcrumbNameMap = {
  //   '/overview': 'Overview',
  //   '/clients': 'Clients',
  //   '/administration': 'Administration'
  // };
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    const lastElement = pathSnippets.length > 1 && pathSnippets[pathSnippets.length - 1] === pathSnippets[index];
    return (
      <Breadcrumb.Item key={url}>
        {!lastElement ? <Link to={url}>{toUpperLatter(pathSnippets[index])}</Link> : toUpperLatter(pathSnippets[pathSnippets.length - 1])}
        {/* {breadcrumbNameMap[url] ? <Link to={url}>{breadcrumbNameMap[url]}</Link> : pathSnippets[pathSnippets.length - 1]} */}
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      Dashboard
    </Breadcrumb.Item>
  ].concat(extraBreadcrumbItems);

  return (
    <Breadcrumb>
      {breadcrumbItems}
      {/* {crumbs && crumbs?.length && crumbs.map((crumb, index) => (
        <Breadcrumb.Item key={index}>{crumb}</Breadcrumb.Item>
      ))} */}
    </Breadcrumb>
  );
}
