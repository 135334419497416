/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import CreateVenue from 'assets/svg/create_company.svg';
import EditCompany from 'assets/svg/edit_company.svg';
import ViewCompany from 'assets/svg/view_company.svg';
import DeleteVenue from 'assets/svg/delete_venue.svg';
import MenuIcon from 'assets/svg/menu_icon.svg';

import classes from './CompanyInfo.module.scss';

export default function CompanyInfo({ company, onChangeCompany }) {
  return (
    <div className={classes.companyInfoWrap}>
      <div className={classes.headerWrapp}>
        <div className={classes.headerText}>{company?.name}</div>
        <div className={classes.headerButtons}>
          <img
            src={CreateVenue || ''}
            alt="create venue"
            className={classes.image}
            onClick={() => onChangeCompany({ type: 'create', company })}
          />
          <img
            src={EditCompany || ''}
            alt="edit company"
            className={classes.image}
            onClick={() => onChangeCompany({ type: 'edit', company })}
          />
          <img
            src={ViewCompany || ''}
            alt="view details company"
            className={classes.image}
            onClick={() => onChangeCompany({ type: 'view', company })}
          />
          <img
            src={DeleteVenue || ''}
            alt="delete company"
            className={classes.image}
            onClick={() => onChangeCompany({ type: 'deleteCompany', company })}
          />
        </div>
      </div>
      <div className={classes.bodyWrapper}>
        {!!company?.venues?.length && company.venues.map(venue => (
          <div key={venue?.id} className={classes.venueWrapp}>
            <div className={classes.venueNameWrap}>
              <img
                src={MenuIcon || ''}
                alt="menu"
                className={classes.venueNameWrap_image}
              />
              <div className={classes.venueNameWrap_name}>{venue.name}</div>
            </div>
            <div className={classes.venueIdWrap}>
              <div className={classes.venueNameWrap_id}>{ `ID: ${venue.id}`}</div>
              <img
                src={DeleteVenue || ''}
                alt="delete venue"
                className={classes.venueIdWrap_image}
                onClick={() => onChangeCompany({ type: 'delete', company: venue })}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
