import { useRef } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import 'antd/dist/reset.css';
import { Menu } from 'components';
import { CSSTransition } from 'react-transition-group';
import classes from './SideBar.module.scss';
import transitionSide from './transitionSide.module.scss';

export default function SideBar({ visible }) {
  const sideRef = useRef(null);
  return (
    <CSSTransition
      in={visible}
      timeout={450}
      classNames={transitionSide}
      unmountOnExit
      nodeRef={sideRef}
    >
      <div ref={sideRef} className={classes.sideBar}>
        <div className={classes.avatarWrapper}>
          <Avatar size="small" icon={<UserOutlined />} />
          <span className={classes.name}>James</span>
        </div>
        <Menu />
      </div>
    </CSSTransition>
  );
}
