/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Breadcrumbs } from 'components';
import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { authOut } from 'redux/authReducer';
import RightBar from 'assets/svg/right_bar.svg';
import cn from 'classnames';
import classes from './Header.module.scss';

export default function HeaderComponent({ sideVisible, rightVisible, setSideVisible, setRightVisible }) {
  const dispatch = useDispatch();
  function LogOut() {
    dispatch(authOut());
  }

  return (
    <div className={classes.header}>
      <img
        src={RightBar || ''}
        alt="side bar"
        className={cn(classes.header_switchLeft, sideVisible && classes.header_switchLeft_on)}
        onClick={() => setSideVisible(!sideVisible)}
      />
      <Breadcrumbs />
      <img
        src={RightBar || ''}
        alt="right bar"
        className={cn(classes.header_switchRight, rightVisible && classes.header_switchRight_on)}
        onClick={() => setRightVisible(!rightVisible)}
      />
      <Button className={classes.button} onClick={LogOut} style={{ marginLeft: 'auto' }}>
        <LogoutOutlined />
      </Button>
    </div>
  );
}
