import { Empty } from 'antd';
import cn from 'classnames';
import classes from './EmptyData.module.scss';

export default function EmptyData({ imageStyle }) {
  return (
    <div className={classes.emprtyWrap}>
      <Empty
        imageStyle={imageStyle}
        style={{ margin: 0 }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    </div>
  );
}
