/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { Input, Button, Form, Row, Col, message } from 'antd';
import { addEditCompany } from 'api/requests';
import { useMutation, useQueryClient } from 'react-query';
// import { onChangeInfo } from 'redux/companiesReducer';
// import { useDispatch, useSelector } from 'react-redux';
import classes from './ModalBodyEditCompany.module.scss';

export default function ModalBodyEditCompany({ company, onClose, add }) {
  const queryClient = useQueryClient();
  const { mutateAsync, isError, isSuccess, isLoading, error, reset } = useMutation({
    mutationFn: body => addEditCompany(body, company?.id),
    onSuccess: () => { queryClient.refetchQueries({ queryKey: ['info/companies'], type: 'active' }); }
  });
  // const infoCompanies = useSelector(state => state?.companies?.infoCompanies);
  // const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      // data.venues = !add ? infoCompanies?.find(comp => comp?.id === data.id)?.venues : [];
      // const changedCompany = infoCompanies?.filter(comp => comp?.id !== data.id).concat([data]);
      // const newCompany = infoCompanies.concat([data]);
      // const dataList = !add ? changedCompany : newCompany;
      // dispatch(onChangeInfo({ data: dataList, key: 'infoCompanies' }));
      message.success(!add ? 'Company success edited' : 'Company success added');
      onClose();
    }
    if (!isLoading && isError) {
      message.error(error?.response?.data?.message || 'Something went wrong');
      reset();
    }
  }, [isError, isSuccess, isLoading]);

  function onFinish({ address, email, manager_name, name, password, phone }) {
    const dataObj = {};
    if (address) {
      dataObj.address = address;
    }
    if (email) {
      dataObj.email = email;
    }
    if (manager_name) {
      dataObj.manager_name = manager_name;
    }
    if (name) {
      dataObj.name = name;
    }
    if (password) {
      dataObj.password = password;
    }
    if (phone) {
      dataObj.phone = phone;
    }
    if (!add) {
      dataObj._method = 'PUT';
    }

    mutateAsync(dataObj);

    // mutateAsync({ address, email, manager_name, name, password, phone });
  }
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {!add ? 'Edit current company ' : 'Add a new company '}
        {company?.name || ''}
      </div>
      <Form
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item
              name="name"
              initialValue={company?.name || ''}
              rules={[
                {
                  required: !!add,
                  message: 'Company name field is required!'
                }
              ]}
            >
              <Input placeholder="Company name " className={classes.input} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="address"
              rules={[
                {
                  required: !!add,
                  message: 'Company address field is required!'
                }
              ]}
              initialValue={company?.address || ''}
            >
              <Input placeholder="Company address " className={classes.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="manager_name"
              rules={[
                {
                  required: !!add,
                  message: 'Manager name field is required!'
                }
              ]}
              initialValue={company?.manager_name || ''}
            >
              <Input placeholder="Manager name " className={classes.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: !!add,
                  message: 'Phone field is required!'
                }
              ]}
              initialValue={company?.phone || ''}
            >
              <Input placeholder="Phone" className={classes.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: !!add,
                  message: 'Email field is required!'
                }
              ]}
              initialValue={company?.email || ''}
            >
              <Input placeholder="Email" className={classes.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: !!add,
                  message: 'Password field is required!'
                }
              ]}
              initialValue={company?.password || ''}
            >
              <Input.Password placeholder="Passsword" className={classes.input} />
            </Form.Item>
          </Col>
          <Col span={24} className={classes.buttonWrap}>
            <Button
              className={classes.button}
              block
              htmlType="submit"
            >
              {!add ? 'Edit current company ' : 'Add this company '}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
