import { useState } from 'react';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { onChangeDate } from 'redux/dateReducer';
import { Calendar } from 'components';
import classes from './CalendarSelect.module.scss';

export default function CalendarSelect() {
  const dispatch = useDispatch();
  const date = useSelector(state => state?.date);
  const [open, setOpen] = useState(false);
  function onCalendarChange({ startDate, endDate }) {
    dispatch(onChangeDate({ startDate, endDate }));
    onCalendarClose();
  }
  function onCalendarClose() {
    setOpen(false);
  }
  return (
    <div className={classes.calendarWrap}>
      <Button
        onClick={() => { setOpen(!open); }}
        className={classes.button}
      >
        {`${moment(date.startDate).format('DD/MM/YYYY')} - ${moment(date.endDate).format('DD/MM/YYYY')}`}
      </Button>
      {/* <Select>
        <Select.Option value="lucy">lucy</Select.Option>
      </Select> */}
      <Modal
        destroyOnClose
        closable={false}
        centered
        open={open}
        onOk={onCalendarClose}
        onCancel={onCalendarClose}
        footer={null}
        className={classes.modal}
        bodyStyle={{ backgroundColor: '#333333' }}
        width="auto"
      >
        <Calendar onCalendarChange={onCalendarChange} date={date} />
      </Modal>
    </div>
  );
}
