import { ViewsGraph } from 'components';
import { useChartsVisitsPrevious } from 'api/requests';

export default function VisitLangGraphPrevious({ body, id }) {
  const { data, error, isFetching } = useChartsVisitsPrevious(body, id);
  return (
    <ViewsGraph
      headerText="Last"
      colors={['#828282']}
      data={data}
      error={error}
      isFetching={isFetching}
    />
  );
}
