import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store, { persistor } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider, theme } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      retryDelay: 0
    }
  }
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: '#FFFFFF0D',
            colorBgBase: '#FFFFFF0D',
            colorBgContainer: '#1C1C1C',
            colorBgLayout: 'rgba(0, 0, 0, 0.8)',
            colorBorder: 'rgba(255, 255, 255, 0.1)'
          },
          components: {
            Input: {
              colorText: '#1C1C1C',
              colorBgContainer: '#FFF',
              colorTextPlaceholder: 'color: rgba(0, 0, 0, 0.2)',
              colorIcon: 'rgba(0, 0, 0, 0.45)'
            },
            Button: {
              colorText: '#FFF',
              colorBgContainer: '#1c1c1c'
            },
            Modal: {
              colorBgElevated: '#333333'
            }
          }
        }}
        >
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </StrictMode>
);
