import cn from 'classnames';
import Modal from 'react-modal';
import classes from './ModalWindow.module.scss';

Modal.setAppElement('#root');

const ModalWindow = ({
  isOpen,
  closeModal,
  children,
  headerText
}) => (
  <Modal
    isOpen={isOpen}
    onAfterOpen={() => null}
    onRequestClose={closeModal}
    overlayClassName={cn(classes.modalBg)}
    className={cn(classes.modalBody)}
    parentSelector={() => document.getElementById('root')}
    bodyOpenClassName=""
    portalClassName="global-modal"
  >
    <div>{headerText}</div>
    <div className={cn(classes.children)}>
      {children}
    </div>
  </Modal>
);

export default ModalWindow;
