import { createSlice } from '@reduxjs/toolkit';
import { updateToken } from 'api/apiInstance';

const initialState = {
  user: null,
  isAuthenticated: false,
  accessToken: null,
  error: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authIn(state, { payload }) {
      const { user, token } = payload;
      if (token) {
        updateToken(token);
      }
      return { user, accessToken: token, isAuthenticated: true, error: false };
    },
    authOut() {
      updateToken(null);
      return { user: null, accessToken: null, isAuthenticated: false, error: false };
    }
  }
});

export const { authIn, authOut } = authSlice.actions;
export default authSlice.reducer;
