import { useCallback } from 'react';
import { ResponsiveLine } from '@nivo/line';
import cn from 'classnames';
import { useChartsTotal } from 'api/requests';
import { LoadWrap } from 'components';
import { randomHexColor } from 'utils/common';
import classes from './TotalUsersGraph.module.scss';

export default function TotalUsersGraph({ id, body }) {
  const { data, error, isFetching } = useChartsTotal(body, id);
  const tooltip = useCallback(
    ({ point }) => (
      <div className={classes.tooltipWrapper}>
        <div className={classes.tooltip}>
          <div className={classes.tooltip_value}>{`${point.data.x}: ${point.data.y}`}</div>
        </div>
        <div className={classes.tooltip_circle} style={{ background: point.serieColor }} />
      </div>
    ),
    []
  );
  const idList = data?.length && data.map(el => el?.id);
  function getColor(value) {
    if (value === 'Total') return '#FF4747';
    if (value === 'en') return '#A1E3CB';
    if (value === 'fr') return '#A8C5DA';
    return randomHexColor();
  }
  const colorsArr = [{ id: 'Total', color: '#FF4747' }, { id: 'en', color: '#A1E3CB' }, { id: 'fr', color: '#A8C5DA' }];
  const colorsListArr = idList?.length ? idList?.map(item => ({ id: item, color: getColor(item) })) : colorsArr;
  const colors = colorsListArr?.map(item => item?.color);

  return (
    <div className={classes.totalUsers}>
      <div className={classes.legend}>
        <div className={classes.legend_totalUsers}>Total Users</div>
        <div className={classes.legend_totalViews}>Total Views</div>
        {colorsListArr.map(item => (
          <div className={cn(classes.legend_views, item?.id === 'Total' && classes.total)} key={item?.id}>
            <div className={classes.point} style={{ background: item?.color }} />
            {item?.id}
          </div>
        ))}
        <div className={classes.dots}>...</div>
      </div>
      <LoadWrap data={data} isFetching={isFetching} error={error}>
        <ResponsiveLine
          data={data}
          margin={{ top: 40, right: 50, bottom: 60, left: 40 }}
          xScale={{ type: 'point' }}
          lineWidth={4}
          animate={false}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false
          }}
          colors={colors}
          tooltip={tooltip}
        // yFormat="time:%Y-m%-%d"
          curve="natural"
          axisTop={null}
          axisRight={null}
          enableGridX={false}
          enableGridY
          gridYValues={4}
          axisBottom={{
            orient: 'bottom',
            tickSize: 0,
            tickPadding: 20,
            tickRotation: 25,
            tickValues: 0
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: 4
          }}
          enablePoints={false}
          areaOpacity={0.5}
          enableCrosshair={false}
          useMesh
          theme={{
            textColor: 'rgba(255, 255, 255, 0.4)',
            fontSize: '12px',
            lineHeight: '18px',
            axis: {
              ticks: {
                line: {
                  stroke: 'rgba(255, 255, 255, 0.05)',
                  strokeWidth: 1
                },
                text: {
                  fontSize: '12px'
                }
              }

            },
            grid: {
              line: {
                stroke: 'rgba(255, 255, 255, 0.05)',
                strokeWidth: '1px'
              }
            }
          }}
        />
      </LoadWrap>
    </div>
  );
}
