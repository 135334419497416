import { string, oneOfType, node, bool } from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import cn from 'classnames';
import './Tooltip.css';
import InnerHTML from 'dangerously-set-html-content';

const TooltipComponent = ({
  className, text, children, background, mode
}) => (
  <Tooltip
    content={typeof text === 'string' ? <InnerHTML html={text} /> : text}
    background={background || '#31254b'}
    padding={mode === 'white' ? '16px' : '8px'}
    direction="down"
    distance={10}
    zIndex={1999}
    className={className}
    tipContentClassName={cn(
      'contentTooltip',
      mode === 'white' ? 'contentTooltip_white' : 'contentTooltip_black'
    )}
  >
    {children || <> </>}
  </Tooltip>
);

TooltipComponent.defaultProps = {
  mode: false,
  text: false,
  className: '',
  children: '',
  background: ''
};

TooltipComponent.propTypes = {
  className: string,
  text: oneOfType([node, bool]),
  children: oneOfType([node, string]),
  mode: oneOfType([string, bool]),
  background: string
};

export default TooltipComponent;
