import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { reduxBatch } from '@manaflair/redux-batch';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';
import immutablePersistenceTransform from 'utils/ImmutablePersistenceTransform';
import { interceptor } from 'api/apiInstance';
import authReducer from './authReducer';
import dateReducer from './dateReducer';
import companiesReducer from './companiesReducer';

const persistConfig = {
  key: 'root',
  storage: new CookieStorage(Cookies, {
    indexKey: 'emagine',
    setCookieOptions: {
      secure: true,
      expires: 7 * 86400
    }
  }),
  version: 1.01,
  transforms: [immutablePersistenceTransform],
  whitelist: ['auth', 'date', 'companies'],
  blacklist: ['_persist']
};

const appReducer = combineReducers({
  auth: authReducer,
  date: dateReducer,
  companies: companiesReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/authOut') {
    return appReducer({}, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'date/onChangeDate'],
      ignoredPaths: ['date.startDate', 'date.endDate']
    }
  }),
  enhancers: [reduxBatch],
  devTools: true,
  debug: true
});
interceptor(store);
export const persistor = persistStore(store);

export default store;
