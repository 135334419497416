import { useCallback } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { mathRound } from 'utils/common';
import { LoadWrap } from 'components';
import classes from './ViewsGraph.module.scss';

export default function ViewsGraph({ headerText, colors, data, isFetching, error }) {
  const tooltip = useCallback(
    ({ data: d }) => (
      <div className={classes.tooltip}>
        {`${d.date}: ${d.views}`}
      </div>
    ),
    []
  );
  return (
    <div className={classes.viewsWrapper}>
      <div className={classes.views_header}>
        {`${headerText} Week views`}
      </div>
      <LoadWrap data={data} isFetching={isFetching} error={error}>
        <ResponsiveBar
          data={data}
          keys={['views']}
          indexBy="date"
          margin={{ top: 20, right: 20, bottom: 30, left: 50 }}
          padding={0.6}
          tooltip={tooltip}
          groupMode="grouped"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          gridYValues={4}
          colors={colors}
          borderRadius={4}
          borderColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                1.6
              ]
            ]
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0
          }}
          axisLeft={{
            tickSize: 5,
            tickRotation: 0,
            format(value) {
              const v = typeof value === 'number' ? mathRound(value) : value;
              return v.length > 10 ? `${v.string(0, 14)}...` : v;
            },
            tickPadding: 5,
            tickValues: 4
          }}
          enableLabel={false}
          role="application"
          theme={{
            textColor: 'rgba(255, 255, 255, 0.4)',
            fontSize: '12px',
            lineHeight: '18px',
            axis: {
              ticks: {
                line: {
                  stroke: 'rgba(255, 255, 255, 0.05)',
                  strokeWidth: 1
                },
                text: {
                  fontSize: '12px'
                }
              }

            },
            grid: {
              line: {
                stroke: 'rgba(255, 255, 255, 0.05)',
                strokeWidth: '1px'
              }
            }
          }}
        />

      </LoadWrap>
    </div>

  );
}
