import moment from 'moment-timezone';

export const numberWithSpaces = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export function mathCeil(count) {
  const length = String(count).length;
  if (length >= 5 && length <= 6) {
    return `${Math.ceil((count / 1000) * 10) / 10}K`;
  }
  if (length > 6) {
    return `${Math.ceil((count / 1000000) * 10) / 10}M`;
  }
  return count;
}

export function mathRound(count) {
  const length = String(count).length;
  if (length >= 4 && length <= 6) {
    return `${Math.ceil((count / 1000) * 10) / 10}K`;
  }
  if (length > 6) {
    return `${Math.ceil((count / 1000000) * 10) / 10}M`;
  }
  return count;
}

export const declOfNum = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const getRandomInt = (min, max) => {
  const minValue = Math.ceil(min);
  const maxValue = Math.floor(max);
  return Math.floor(Math.random() * (maxValue - minValue)) + minValue; // max не включается, min включается
};

export const isCustomPage = (pageData, paramsPage) => {
  let isCustom = false;
  if (pageData.find(item => Number(item.id) === Number(paramsPage))) {
    isCustom = true;
  }
  return isCustom;
};

export const isPresetPage = (dataPage, paramsPage) => {
  const preset = dataPage?.filter(item => Number(item.id) === Number(paramsPage));
  return preset?.length ? preset[0].readOnly : false;
};

export const isFilledArray = arr => (Array.isArray(arr) && arr.length ? true : null);

export const toUpperLatter = str => (str ? str.charAt(0).toUpperCase() + str.slice(1) : '');

export const toPascalCase = str => (str ? str.split('_').map(i => toUpperLatter(i.toLocaleLowerCase()))?.join('') : null);

export const debounce = (fn, delay) => {
  let timeOutId;
  return function (...args) {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const checkQueryParams = (params) => {
  const paramsUrl = new URLSearchParams();
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        paramsUrl.append(key, value);
      }
    });
  }

  return paramsUrl.toString() ? `?${paramsUrl.toString()}` : '';
};

export const toneColor = (value, color) => {
  const lowerValue = value?.toLowerCase();
  if (lowerValue === 'негатив' || lowerValue === 'negative' || lowerValue === 'негатив') {
    return Array.isArray(color) ? color[0] : { color1: color?.color1 };
  }
  if (lowerValue === 'нейтрал' || lowerValue === 'neutral' || lowerValue === 'нейтрал') {
    return Array.isArray(color) ? color[1] : { color2: color?.color2 };
  }
  if (lowerValue === 'позитив' || lowerValue === 'positive' || lowerValue === 'позитив') {
    return Array.isArray(color) ? color[2] : { color3: color?.color3 };
  }
  return '';
};

export const widgetsToneColor = (arr, color) => arr.map(val => toneColor(val, color));

export const randomHexColor = () => `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}80`;
export const randomHexColor100 = () => `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;

const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
const randomByte = () => randomNumber(0, 255);
const randomPercent = () => (randomNumber(50, 100) * 0.01).toFixed(2);
export const randomCssRgba = () => `rgba(${[randomByte(), randomByte(), randomByte(), randomPercent()].join(',')})`;

export const repeatColor = (data, colors) => {
  const dataLength = data?.length || 0;
  const colorsLength = colors?.length || 0;
  const diff = dataLength - colorsLength;
  if (diff > 0) {
    const colorsFillArr = Array(diff).fill(1).map(() => randomHexColor().toUpperCase());
    return colors.concat(colorsFillArr);
  }
  return colors;
};
export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

export function delayPromise(duration, data) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, duration);
  });
}
export const timeZones = moment.tz.names()?.map(zona => ({ value: zona, label: zona }));
