import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'normalize.css';
import classes from 'App.module.scss';
import Routes from 'routes';
import { updateToken, apiToken } from 'api/apiInstance';
import { Layout } from 'antd';

function App() {
  const accessToken = useSelector(state => state?.auth?.accessToken);
  if (accessToken && !apiToken) {
    updateToken(accessToken);
  }

  return (
    <Layout className={classes.app} id="global-wrap">
      {/* <RouterProvider router={Routes} /> */}
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Layout>
  );
}

export default App;
