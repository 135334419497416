import { createBrowserRouter, Navigate, Routes, Route, Link } from 'react-router-dom';
import { PrivateRoute } from 'components';
import { Layout, Login, Clients, Overview, Administration } from 'containers';

// export default createBrowserRouter([
//   {
//     path: '/login',
//     element: <Login />
//   },
//   {
//     path: '/',
//     element: <PrivateRoute component={Layout} />,
//     handle: {
//       crumb: () => 'Dasboards'
//     },
//     children: [
//       { index: true, element: <Navigate to="/overview" replace /> },
//       {
//         path: '/overview',
//         element: <Overview />,
//         handle: {
//           crumb: () => <Link to="/overview">Overview</Link>
//         },
//         children: [
//           {
//             path: '/overview/:id',
//             element: <Overview />,
//             handle: {
//               crumb: val => val || 'id'
//             }
//           }
//         ]
//       },
//       {
//         path: '/clients',
//         element: <Clients />,
//         handle: {
//           crumb: () => <Link to="/clients">Clients</Link>
//         },
//         children: [
//           {
//             path: '/clients/:id',
//             element: <Clients />,
//             handle: {
//               crumb: val => val || 'id'
//             }
//           }
//         ]

//       },
//       { path: '*', element: <Navigate to="/overview" replace /> }
//     ]
//   }
// ]);

const RoutesComponent = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/" element={<PrivateRoute component={Layout} />}>
      <Route index element={<Navigate to="/overview" replace />} />
      <Route path="/overview" element={<PrivateRoute component={Overview} />}>
        <Route path=":company_id" element={<PrivateRoute component={Overview} />}>
          <Route path=":venue_id" element={<PrivateRoute component={Overview} />} />
        </Route>
      </Route>
      <Route path="/clients" element={<PrivateRoute component={Clients} />}>
        <Route path=":company_id" element={<PrivateRoute component={Clients} />}>
          <Route path=":venue_id" element={<PrivateRoute component={Clients} />} />
        </Route>
      </Route>
      <Route path="/administration" element={<PrivateRoute component={Administration} />} />
      <Route
        path="*"
        element={<Navigate to="/overview" replace />}
      />
    </Route>
  </Routes>
);
export default RoutesComponent;
