import { useEffect } from 'react';
import { Row, Button, Col, message } from 'antd';
import { deleteVenue } from 'api/requests';
import { useMutation, useQueryClient } from 'react-query';
import cn from 'classnames';
// import { useDispatch, useSelector } from 'react-redux';
// import { onChangeInfo } from 'redux/companiesReducer';
import classes from './ModalBodyDeleteVenue.module.scss';

export default function ModalBodyDeleteVenue({ venue, onClose }) {
  const queryClient = useQueryClient();
  const { mutateAsync, isError, isSuccess, isLoading, error, reset } = useMutation({
    mutationFn: id => deleteVenue(id),
    onSuccess: () => { queryClient.refetchQueries({ queryKey: ['info/companies'], type: 'active' }); }
  });
  // const infoCompanies = useSelector(state => state?.companies?.infoCompanies);
  // const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      // const newList = infoCompanies.map(el => (el?.id === venue?.company_id
      //   ? { ...el, venues: el.venues?.filter(elem => elem?.id !== venue?.id) } : el));
      // dispatch(onChangeInfo({ data: newList, key: 'infoCompanies' }));
      message.success('Venue success deleted');
      onClose();
    }
    if (!isLoading && isError) {
      message.error(error?.response?.data?.message || 'Something went wrong');
      reset();
    }
  }, [isError, isSuccess, isLoading]);

  function onDelete() {
    mutateAsync(venue?.id);
  }
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {`Are you sure you want to delete ${venue?.name}?`}
      </div>
      <div className={classes.buttonsWrap}>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Button
              className={cn(classes.button, classes.button_cancel)}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className={classes.button}
              onClick={onDelete}
              style={{ backgroundColor: '#F2994A' }}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
