import { useEffect } from 'react';
import { Input, Button, Form, message } from 'antd';
import { createVenue } from 'api/requests';
import { useMutation, useQueryClient } from 'react-query';
// import { onChangeInfo } from 'redux/companiesReducer';
// import { useSelector, useDispatch } from 'react-redux';
import classes from './ModalBodyAddVenue.module.scss';

export default function ModalBodyAddVenue({ company, onClose }) {
  const queryClient = useQueryClient();
  const { mutateAsync, isError, isSuccess, isLoading, error, reset } = useMutation({
    mutationFn: body => createVenue(body),
    onSuccess: () => { queryClient.refetchQueries({ queryKey: ['info/companies'], type: 'active' }); }
  });
  // const infoCompanies = useSelector(state => state?.companies?.infoCompanies);
  // const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      // const newList = infoCompanies.map(el => (el?.id === company?.id
      //   ? { ...el, venues: el.venues?.concat([data]) } : el));
      // dispatch(onChangeInfo({ data: newList, key: 'infoCompanies' }));
      onClose() && message.success('Venue success added');
    }
    if (!isLoading && isError) {
      message.error(error?.response?.data?.message || 'Something went wrong');
      reset();
    }
  }, [isError, isSuccess, isLoading]);

  function onFinish({ name }) {
    mutateAsync({ name, company_id: company?.id });
  }
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {'Add a new value for '}
        {company.name}
      </div>
      <Form
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input venue name'
            }
          ]}
        >
          <Input placeholder="Venue name " className={classes.input} />
        </Form.Item>
        <Button
          className={classes.button}
          htmlType="submit"
        >
          Add new venue
        </Button>
      </Form>
    </div>
  );
}
