import ReactLoading from 'react-loading';
import { string, node, bool } from 'prop-types';
import cn from 'classnames';
import classes from './Loader.module.scss';

const Loader = ({
  type, fetching, children
}) => (fetching ? (
  <div className={cn(classes.loderWrap)}>
    <ReactLoading
      type={type}
      style={{ maxWidth: '64px', width: '20%', fill: '#8C85A8' }}
    />
  </div>
) : children);
Loader.defaultProps = {
  type: 'spinningBubbles',
  fetching: false,
  children: null
};

Loader.propTypes = {
  type: string,
  fetching: bool,
  children: node
};
export default Loader;
