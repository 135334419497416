import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const initialState = {
  startDate: moment().format(),
  endDate: moment().add(7, 'day').format(),
  key: 'selection',
  bodyDate: { from: moment().format('YYYY-MM-DD'), to: moment().add(7, 'day').format('YYYY-MM-DD') }
};

const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    onChangeDate(state, { payload }) {
      const { startDate, endDate } = payload;
      state.startDate = startDate;
      state.endDate = endDate;
      state.bodyDate.from = moment(startDate).format('YYYY-MM-DD');
      state.bodyDate.to = moment(endDate).format('YYYY-MM-DD');
    },
    onClearDate() {
      return { startDate: null, endDate: null, key: 'selection' };
    }
  }
});

export const { onChangeDate, onClearDate } = dateSlice.actions;
export default dateSlice.reducer;
