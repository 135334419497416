import { Divider, Menu } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { LoadWrap } from 'components';
import {
  useNavigate, useLocation
} from 'react-router-dom';
import classes from './ClientsMenu.module.scss';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  };
}

export default function ClientsMenu({ companyInfo }) {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(el => el);
  const navigate = useNavigate();
  const { data, isFetching, error } = companyInfo;

  // const { company_id, venue_id } = id;
  // const [current, setCurrent] = useState([venue_id]);
  // const [opened, setOpened] = useState([company_id]);

  // useEffect(() => {
  //   const selected = venue_id ? `${venue_id}/` : null;
  //   const open = company_id || null;
  //   setCurrent(selected);
  //   setOpened(open);
  // }, [venue_id]);

  const onClick = (e) => {
    const venueId = e.key.replace(/.$/, '');
    const companyId = e.keyPath[1];
    const path = (pathSnippets[0] && pathSnippets[0] !== 'administration') ? pathSnippets[0] : 'overview';
    navigate(`${path}/${companyId}/${venueId}`);
    // setCurrent(String(e.key));
  };

  const items = !!data?.length && data.map(client => getItem(
    client.name,
    client.id,
    null,
    client?.venues?.map(venue => getItem(venue.name, `${venue.id}/`, <div className={classes.iconWrapper}><RightOutlined width="7px" style={{ color: '#1c1c1c' }} /></div>))
  ));
  return (
    <div className={classes.clientsMenu}>
      <h3 className={classes.clientsMenu_header}>Clients</h3>
      <Divider style={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
      <LoadWrap data={!!data?.length} isFetching={isFetching} error={error}>
        <div className={classes.clientsMenuWrap}>
          <Menu
            onClick={onClick}
            style={{ border: 'none' }}
            className={classes.customSubmenu}
          // defaultOpenKeys={opened}
          // selectedKeys={current}
            mode="inline"
            items={items}
          />
        </div>
      </LoadWrap>

    </div>
  );
}
