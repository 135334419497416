import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import cn from 'classnames';
import classes from './Menu.module.scss';

export default function MenuComponent() {
  const [select, setSelect] = useState('overview');
  const href = window.location.href;
  useEffect(() => {
    const selected = href.split('/')[1];
    setSelect(selected);
  }, [href]);

  const items = [
    { label: <Link to="/overview">Overview</Link>, key: 'overview' }, // remember to pass the key prop
    { label: <Link to="/clients">Clients</Link>, key: 'clients' }, // which is required
    { label: <Link to="/administration">Administration</Link>, key: 'administration' } // which is required
  ];
  return (
    <nav className={classes.navigation}>
      <Menu
        className={cn(classes.list)}
        defaultSelectedKeys={[select]}
        items={items}
      />
    </nav>
  );
}
