import { ViewsGraph } from 'components';
import { useChartsVisitsCurrent } from 'api/requests';

export default function ViewsGraphCurrent({ body, id }) {
  const { data, error, isFetching } = useChartsVisitsCurrent(body, id);
  return (
    <ViewsGraph
      headerText="This"
      colors={['#FFE999']}
      data={data}
      error={error}
      isFetching={isFetching}
    />
  );
}
