import { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import 'antd/dist/reset.css';
import { Header, SideBar, RightBar } from 'containers';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useCompanyInfoTotal, useCompanyInfo } from 'api/requests';
// import isEqual from 'lodash/isEqual';
// import { onChangeInfo } from 'redux/companiesReducer';
import classes from './layout.module.scss';

function LayoutComponent() {
  const [sideVisible, setSideVisible] = useState(true);
  const [rightVisible, setRightVisible] = useState(true);
  const { company_id, venue_id } = useParams();
  const body = useSelector(state => state?.date?.bodyDate);
  const companyInfoTotal = useCompanyInfoTotal(body, { company_id, venue_id });
  const companyInfo = useCompanyInfo(body);
  // const dispatch = useDispatch();
  // const infoCompanies = useSelector(state => state?.companies?.infoCompanies);
  // useEffect(() => {
  //   if (companyInfo.data && !isEqual(companyInfo.data, infoCompanies)) {
  //     dispatch(onChangeInfo({ data: companyInfo.data, key: 'infoCompanies' }));
  //   }
  // }, [companyInfo.data]);

  return (
    <div className={classes.layoutWrap}>
      <div className={cn(classes.sideBar, sideVisible && classes.sideBar_active)}>
        <SideBar visible={sideVisible} />
      </div>
      <div className={cn(
        classes.layoutMain,
        rightVisible && !sideVisible && classes.layoutMain_rightOn,
        sideVisible && !rightVisible && classes.layoutMain_sideOn,
        rightVisible && sideVisible && classes.layoutMain_bothOn
      )}
      >
        <Header sideVisible={sideVisible} rightVisible={rightVisible} setSideVisible={setSideVisible} setRightVisible={setRightVisible} />
        <div className={classes.content}>
          <Outlet context={{ companyInfoTotal, companyInfo, body, id: { company_id, venue_id } }} />
        </div>
      </div>
      <div className={cn(classes.rightBar, rightVisible && classes.rightBar_active)}>
        <RightBar visible={rightVisible} companyInfoTotal={companyInfoTotal} companyInfo={companyInfo} />
      </div>
    </div>
  );
}
export default LayoutComponent;
