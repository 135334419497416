import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  infoCompanies: []
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    onChangeInfo(state, { payload }) {
      const { key, data } = payload;
      state[key] = data;
    }
  }
});

export const { onChangeInfo } = companiesSlice.actions;
export default companiesSlice.reducer;
