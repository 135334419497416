// import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { CalendarSelect, ViewsGraphPrevious, ViewsGraphCurrent } from 'components';
import { useOutletContext } from 'react-router-dom';
import classes from './Clients.module.scss';

export default function Clients() {
  // const { company_id, venue_id } = useParams();
  // const infoCompanies = useSelector(state => state?.companies?.infoCompanies);
  // const body = useSelector(state => state?.date?.bodyDate);
  const { companyInfo, body, id } = useOutletContext();
  const { company_id, venue_id } = id;
  const infoCompanies = companyInfo?.data;
  const venueList = venues => (venue_id ? venues?.filter(venue => String(venue?.id) === venue_id) : venues);
  const companiesList = company_id ? infoCompanies?.filter(company => String(company?.id) === company_id) : infoCompanies;

  return (
    <div className={classes.clients}>
      <CalendarSelect />
      {!!companiesList?.length && companiesList.map(company => (
        <div key={company.id}>
          <div className={classes.clients_header}>
            {company.name}
          </div>
          <div>
            {!!venueList(company?.venues)?.length && venueList(company.venues).map(venue => (
              <Row gutter={[{ sm: 8, md: 8, lg: 16, xl: 28 }, 0]} key={venue.id}>
                <Col sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }}>
                  <ViewsGraphPrevious id={{ company_id: company.id, venue_id: venue.id }} body={body} />
                </Col>
                <Col sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }}>
                  <ViewsGraphCurrent id={{ company_id: company.id, venue_id: venue.id }} body={body} />
                </Col>
              </Row>
            ))}

          </div>
        </div>
      ))}

    </div>
  );
}
