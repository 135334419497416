import React from 'react';
import { Avatar, Image } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import classes from './ModalBodyView.module.scss';

export default function ModalBodyView({ company }) {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Avatar
          {...(!company.avatar ? { src: <Image src={company.avatar} style={{ width: 18 }} /> } : { icon: <UserOutlined /> })}
          size="medium"
        />
        {company.name}
      </div>
      <div>
        {'Address: '}
        <u>{company.address}</u>
      </div>
      <div>
        {'Phone: '}
        <u>{company.phone}</u>
      </div>
      <div>
        {'Email: '}
        <u>{company.email}</u>
      </div>
      <div>
        {'Manager: '}
        <u>{company.manager_name}</u>
      </div>
    </div>
  );
}
