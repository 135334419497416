// import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { useOutletContext } from 'react-router-dom';
// import { useCompanyInfoTotal } from 'api/requests';
import { CalendarSelect, BlueBlock, TotalUsersGraph, ViewsGraphPrevious, ViewsGraphCurrent, ClientTrafficGraph } from 'components';
import classes from './Overview.module.scss';

export default function Overview() {
  const { companyInfoTotal, body, id } = useOutletContext();
  // const body = useSelector(state => state?.date?.bodyDate);
  // const { company_id, venue_id } = useParams();
  // const { data, error, isFetching } = useCompanyInfoTotal(body, { company_id, venue_id });
  const { data, error, isFetching } = companyInfoTotal;

  return (
    <div className={classes.overview}>
      <Row>
        <Col span={24}>
          <CalendarSelect />
        </Col>
      </Row>
      <Row gutter={[{ xs: 8, sm: 8, md: 8, lg: 16, xl: 28 }, 0]}>
        <Col span={12}>
          <BlueBlock header="Views" value={data?.views} isFetching={isFetching} error={error} imageStyle={{ height: '25px' }} />
        </Col>
        {/* <Col span={8}>
          <BlueBlock header="Active" value={data?.active_venues} isFetching={isFetching} error={error} imageStyle={{ height: '25px' }} />
        </Col> */}
        <Col span={12}>
          <BlueBlock header="Language" value={data?.language} isFetching={isFetching} error={error} imageStyle={{ height: '25px' }} className={classes.blockLang} />
        </Col>
      </Row>
      <Row gutter={[{ xs: 8, sm: 8, md: 8, lg: 16, xl: 28 }, 0]}>
        <Col sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 18 }}>
          <TotalUsersGraph id={id} body={body} />
        </Col>
        <Col sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 6 }}><ClientTrafficGraph body={body} /></Col>
      </Row>
      <Row gutter={[{ xs: 8, sm: 8, md: 8, lg: 16, xl: 28 }, 0]}>
        <Col sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }}>
          <ViewsGraphPrevious id={id} body={body} />
        </Col>
        <Col sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }}>
          <ViewsGraphCurrent id={id} body={body} />
        </Col>
      </Row>
    </div>
  );
}
